import { useRootRedirect } from '../utils/RootRedirectUtils';
import Redirect from '@watershed/shared-frontend/components/Redirect';
import { useOwner } from '@watershed/shared-frontend/hooks/useOwner';
import { Teams } from '@watershed/shared-universal/teams';

/**
 * "Root" = "/". This page always redirects to a landing page tailored to the
 * user.
 */
export default function RootPage() {
  useOwner(Teams.EnterpriseFoundations);
  const redirect = useRootRedirect();
  const url = new URL(redirect, window.location.href);

  // Preserve query params from the original URL, e.g. `?loginAsRedirect=true`
  new URLSearchParams(window.location.search).forEach((value, key) => {
    url.searchParams.set(key, value);
  });

  const to = [url.pathname, url.search].join('');
  return <Redirect to={to} />;
}
